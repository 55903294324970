import { UICell, UISheet } from "../../types/UITypes"
import { useContext, useMemo } from "react"
import "./SheetRenderer.css"
import { AppContext } from "../../AppContext"
import clsx from "clsx"

type CellContextMenuProps = {
  cell: UICell
  cellWithContextMenu?: { cellId: string; target: "rowIndex" | "cell" }
  sheet: UISheet
  target: "rowIndex" | "cell"
  showColumnContextMenu?: boolean
  showRowContextMenu?: boolean
}

export const CellContextMenu = ({
  cell,
  cellWithContextMenu,
  sheet,
  target,
  showColumnContextMenu = false,
  showRowContextMenu = false,
}: CellContextMenuProps) => {
  const {
    onAddRow,
    onRemoveRow,
    isPendingApiResponse,
    onAddColumn,
    onRemoveColumn,
  } = useContext(AppContext)

  const isMenuOpen = useMemo(
    () =>
      cellWithContextMenu?.cellId === cell.id &&
      cellWithContextMenu?.target === target,
    [cellWithContextMenu, cell.id, target]
  )

  console.log(
    cellWithContextMenu?.cellId,
    cell.id,
    cellWithContextMenu?.target,
    target,
    isMenuOpen
  )

  if (!isMenuOpen) {
    return null
  }

  return (
    <div
      key={cell.id}
      className={clsx("cell-menu", {
        "cell-menu-disabled": isPendingApiResponse,
      })}
    >
      {showRowContextMenu && (
        <>
          <div
            className="cell-menu-item"
            onClick={() =>
              !isPendingApiResponse && onAddRow(sheet.id, cell.rowIndex)
            }
          >
            Add Row
          </div>
          <div
            className="cell-menu-item"
            onClick={() => onRemoveRow(sheet.id, cell.rowIndex)}
          >
            Delete Row
          </div>
        </>
      )}
      {showColumnContextMenu && (
        <>
          <div
            className="cell-menu-item"
            onClick={() =>
              !isPendingApiResponse && onAddColumn(sheet.id, cell.columnIndex)
            }
          >
            Add Column
          </div>
          <div
            className="cell-menu-item"
            onClick={() => onRemoveColumn(sheet.id, cell.columnIndex)}
          >
            Delete Column
          </div>
        </>
      )}
    </div>
  )
}
export default CellContextMenu
