export const getFirebaseTemplate = (apiData) => `
  const { onRequest } = require("firebase-functions/v2/https");
  const Mexp = require("math-expression-evaluator")
  
  const api = ${JSON.stringify(apiData)}

  const CellType = {
    Number: 1,
    Text: 2,
    Date: 3,
  }
  
  const UILogicType = {
    AND: "018f6f25-cf1e-73f8-aafb-b8c999f5cd54",
    OR: "018f6f25-cf21-7c4c-aeb6-4e623d007f28",
  }
  
  const getTypedValue = (passedValue, cellType, requestData) => {
    switch (cellType) {
      case CellType.Number: {
        let numericValue = parseFloat(passedValue)
  
        // check if cellName is a reference to another field in the requestData
        if (isNaN(numericValue) && requestData[passedValue]) {
          numericValue = parseFloat(requestData[passedValue])
        }
  
        return numericValue
      }
  
      case CellType.Text: {
        return passedValue
      }
  
      default: {
        return passedValue
      }
    }
  }
  
  const numericOperators = ["eq", "gt", "lt", "ge", "le"]
  const numbersOnlyRegexp = /^\\d+$/

  const getCellType = (cell, requestData) => {
    const { operator, value, name } = cell
    // cell value might be a reference to another cell / request parameter
    const referenceValue = (value && requestData[value]) || value
    const leftHandSideValue = (name && requestData[name]) || name

    let calculatedCellType = CellType.Text

    if (
      operator &&
      numericOperators.includes(operator) &&
      numbersOnlyRegexp.test(referenceValue) &&
      numbersOnlyRegexp.test(leftHandSideValue)
    ) {
      calculatedCellType = CellType.Number
    }

    if (
      !calculatedCellType &&
      operator &&
      operator === "eq" &&
      !numbersOnlyRegexp.test(referenceValue)
    ) {
      calculatedCellType = CellType.Text
    }

    return calculatedCellType
  }

  const evaluateCell = (cell, requestData) => {
    const { name, value, operator, cellLogicType, conditions } = cell

    if (cellLogicType && conditions) {
      const evaluatedConditions = conditions.map((condition) =>
        evaluateCell(condition, requestData)
      )
  
      return cellLogicType.toLowerCase() === "and"
        ? !evaluatedConditions.some((value) => !value)
        : evaluatedConditions.some((value) => value)
    }
  
    const referenceValue = (value && requestData[value]) || value
    const leftHandSideValue = (name && requestData[name]) || name
  
    let calculatedCellType = CellType.Text
  
    if (
      operator &&
      numericOperators.includes(operator) &&
      numbersOnlyRegexp.test(referenceValue) &&
      numbersOnlyRegexp.test(leftHandSideValue)
    ) {
      calculatedCellType = CellType.Number
    }
  
    if (
      !calculatedCellType &&
      operator &&
      operator === "eq" &&
      !numbersOnlyRegexp.test(referenceValue)
    ) {
      calculatedCellType = CellType.Text
    }
  
    if (!name || !referenceValue || !calculatedCellType || !operator) {
      return true
    }
  
    const cellValue = getTypedValue(
      referenceValue,
      calculatedCellType,
      requestData
    )
    const passedValue = getTypedValue(
      leftHandSideValue,
      calculatedCellType,
      requestData
    )
  
    switch (operator) {
      case "eq": {
        switch (calculatedCellType) {
          case CellType.Number:
          case CellType.Text: {
            return cellValue === passedValue
          }
        }
  
        return false
      }
  
      case "gt": {
        switch (calculatedCellType) {
          case CellType.Number: {
            return passedValue > cellValue
          }
        }
  
        return false
      }
  
      case "lt": {
        switch (calculatedCellType) {
          case CellType.Number: {
            return passedValue < cellValue
          }
        }
  
        return false
      }
  
      case "ge": {
        switch (calculatedCellType) {
          case CellType.Number: {
            return passedValue >= cellValue
          }
        }
  
        return false
      }
  
      case "le": {
        switch (calculatedCellType) {
          case CellType.Number: {
            return passedValue <= cellValue
          }
        }
  
        return false
      }
  
      default:
        return false
    }
  }

  const canBeEvaluatedAsBoolean = (cell) => {
    return (
      !!(cell.name && cell.operator && cell.value) ||
      !!(cell.cellLogicType && cell.conditions)
    )
  }
  
  const hasInputData = (cell, inputData) => {
    const cellHasInputData =
    (inputData[cell.name] !== undefined &&
      inputData[cell.name] !== null &&
      inputData[cell.name] !== "") ||
    (cell.value !== undefined &&
      cell.value !== null &&
      cell.value !== "" &&
      inputData[cell.value] !== undefined &&
      inputData[cell.value] !== null &&
      inputData[cell.value] !== "")

    if (cellHasInputData) {
      return true
    }

    if (cell.cellLogicType && cell.conditions) {
      const someCellConditionHasInputData = cell.conditions.map(
        (apiCell) => hasInputData(apiCell, inputData)
      )

      return someCellConditionHasInputData.some((value) => value)
    }

    return false
  }
  
  const getAnswer = (cell, apiSheet, inputData, isAnswerCell) => {
    const cellCanBeEvaluated = canBeEvaluatedAsBoolean(cell)
    const cellHasInputData = hasInputData(cell, inputData)
  
    if (!isAnswerCell && cellCanBeEvaluated && cellHasInputData) {
      const cellEvaluationResult = evaluateCell(cell, inputData)
      return cell.isReverseCondition ? !cellEvaluationResult : cellEvaluationResult
    }
  
    if (cell?.logicType !== undefined && cell.prev) {
      const logicCellAnswers = cell.prev.map((prevCell) => getAnswer(prevCell, apiSheet, inputData))
  
      return cell?.logicType === UILogicType.AND
        ? !logicCellAnswers.some((answer) => !answer)
        : logicCellAnswers.some((answer) => answer)
    }
  
    if (cell.prev?.logicType === undefined && cell.prev) {
      const answer = getAnswer(cell.prev, apiSheet, inputData)
  
      if (answer) {
        // Define data described by the current cell according to the prev cell value.
        // Example: current cell: a === 1, prev cell b > 2. If b eq 3 and a is not defined, then assign inputData.a = 1.
        if (cell.operator === "eq") {
          inputData[cell.name] = cell.value
        }
      }
  
      return answer
    }
  
    if (cell.prev?.logicType !== undefined && cell.prev) {
      const logicCellAnswers = cell.prev.prev.map((prevCell) => getAnswer(prevCell, apiSheet, inputData))
  
      return cell.prev?.logicType === UILogicType.AND
        ? !logicCellAnswers.some((answer) => !answer)
        : logicCellAnswers.some((answer) => answer)
    }

    // Return true if the cell is empty
    return cell.name === "" && cell.operator === "" && cell.value === ""
  }
  
  const executeApi = (
    inputData,
    apiSheetId
  ) => {
    const apiSheet = api.find(({ sheetId }) => sheetId === apiSheetId)

    if (!apiSheet) {
      return null
    }
  
    const results = []

    apiSheet.cells
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach((cell) => {
        const answer = getAnswer(cell, apiSheet, inputData, true)

        results.push(answer)
      })

    const mappedResults = results.map((result, i) => {
      const resultsRow = apiSheet.apiRows[i].sort(
        (a, b) => a.columnIndex - b.columnIndex
      )
  
      if (!result) {
        return resultsRow.map((cell) => ({
          id: cell.id,
          name: cell.name,
          value: false,
          rowIndex: cell.rowIndex,
          columnIndex: cell.columnIndex
        }))
      }
  
      return resultsRow.map((cell) => {
        const mexp = new Mexp()
        let mathExpression = cell.value?.replace("=", "") || ""
        const inputVarNames = Object.keys(inputData)
  
        let isMathExpression = false
  
        inputVarNames.forEach((inputVarName) => {
          if (mathExpression.includes(inputVarName)) {
            isMathExpression = true
  
            mathExpression = mathExpression.replaceAll(
              inputVarName,
              inputData[inputVarName]
            )
          }
        })
  
        if (isMathExpression) {
          try {
            const evaluationResult = mexp.eval(mathExpression, [], inputData)
  
            return {
              id: cell.id,
              name: cell.name,
              value: evaluationResult,
              rowIndex: cell.rowIndex,
              columnIndex: cell.columnIndex
            }
          } catch (e) {
            return {
              id: cell.id,
              name: cell.name,
              value: getTypedValue(cell.value, getCellType(cell, inputData), inputData),
              rowIndex: cell.rowIndex,
              columnIndex: cell.columnIndex
            }
          }
        } else {
          const typedCellValue = getTypedValue(
            cell.value,
            getCellType(cell, inputData),
            inputData
          )
  
          inputData[cell.name] = typedCellValue
  
          return {
            id: cell.id,
            name: cell.name,
            value: typedCellValue,
            rowIndex: cell.rowIndex,
            columnIndex: cell.columnIndex
          }
        }
      })
    })

    return {
      apiSheet: {
        id: apiSheet.sheetId,
        name: apiSheet.sheetName,
      },
      values: mappedResults,
    }
  }
  
  exports.getApiResponse = onRequest(async (req, res) => {
    if (req.method === 'OPTIONS') {
      // Send response to OPTIONS requests
      res.set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
      res.set('Access-Control-Allow-Headers', 'Content-Type');
      res.set('Access-Control-Max-Age', '3600');
      res.status(204).send('');
    } else {
      const { inputData, apiSheetId } = req.body;
      const result = executeApi(inputData, apiSheetId);
  
      res.json({ result });
    }
  });
`
