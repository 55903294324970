import { Handle, Position } from "reactflow"
import "./SheetRenderer.css"
import { UICell, UINode, UISheet } from "../../types/UITypes"
import clsx from "clsx"
import { MouseEvent, useContext } from "react"
import { AppContext } from "../../AppContext"
import { FormulaEditor } from "../FormulaEditor"
import CellLabelRenderer from "./CellLabelRenderer"
import { CELL_WIDTH, ROW_INDEX_WIDTH } from "../../utils/constants"
import CellContextMenu from "./CellContextMenu"

type CellRendererProps = {
  cell: UICell
  editedCell?: UICell
  rowSize: number
  sheet: UISheet
  connectionModeEnabled: boolean
  resizeState: {
    isActive: boolean
    width: number
    height: number
    initialWidth: number
  }
  isSheetSelected: boolean
  onAddColumn: (sheetId: string, columnIndex: number) => void
  onCellMouseUp: (event: MouseEvent, rowIndex: number) => void
  onEditCell: (formulaEditorInlineValue: string, cell: UICell) => Promise<void>
  getNodeTopPos: (
    cell: UICell,
    i: number,
    nodeType: string,
    edgeHandles: UINode[]
  ) => string
  isComplexSheetLeftColumnCell?: boolean
  showConnectionHandles?: boolean
  showColumnContextMenu?: boolean
  showRowContextMenu?: boolean
}

export const CellRenderer = ({
  sheet,
  cell,
  onCellMouseUp,
  isSheetSelected,
  rowSize,
  onEditCell,
  getNodeTopPos,
  connectionModeEnabled,
  resizeState,
  isComplexSheetLeftColumnCell,
  showConnectionHandles = true,
  showColumnContextMenu = false,
  showRowContextMenu = false,
}: CellRendererProps) => {
  const {
    sheetFields,
    isSheetsUIVisible,
    highlightedPathCellIds,
    setHighlightedPathDestCellId,
    onCellClick,
    selectedCell,
    editedCell,
    cellWithContextMenu,
    setCellWithContextMenu,
  } = useContext(AppContext)

  let calculatedWidth = CELL_WIDTH
  // let calculatedWidth = isComplexSheetLeftColumnCell
  //   ? SHORT_CELL_WIDTH
  //   : CELL_WIDTH

  if (resizeState?.isActive && cell.columnIndex === rowSize) {
    calculatedWidth =
      resizeState?.width - ROW_INDEX_WIDTH - CELL_WIDTH * (rowSize - 1)
  }
  // if (resizeState?.isActive && cell.columnIndex === rowSize) {
  //   calculatedWidth =
  //     resizeState?.width -
  //     ROW_INDEX_WIDTH -
  //     CELL_WIDTH * (rowSize - 2) -
  //     (isComplexSheetLeftColumnCell ? SHORT_CELL_WIDTH : CELL_WIDTH)
  // }

  return (
    <div
      key={cell.id}
      id={`cell-${cell.id}`}
      className={clsx("cell", {
        "edited-cell": editedCell?.id === cell.id,
        "resize-active": resizeState?.isActive,
        "highlighted-cell":
          editedCell?.id !== cell.id &&
          highlightedPathCellIds.includes(cell.id),
        "selected-cell": selectedCell?.id === cell.id,
        "complex-sheet-left-column-cell": isComplexSheetLeftColumnCell,
      })}
      onMouseOver={() => setHighlightedPathDestCellId(cell.id)}
      onMouseOut={() => setHighlightedPathDestCellId(undefined)}
      onMouseUp={(event: MouseEvent) => onCellMouseUp(event, cell.rowIndex)}
      style={{
        width: calculatedWidth,
        maxWidth: calculatedWidth,
      }}
      onClick={() => onCellClick(cell)}
      onContextMenu={(event) => {
        event.preventDefault()
        event.stopPropagation()

        setCellWithContextMenu({ cellId: cell.id, target: "cell" })
      }}
    >
      {/* {selectedCell?.id === cell.id && editedCell?.id === cell.id ? ( */}
      {selectedCell?.id === cell.id ? (
        <FormulaEditor
          cell={cell}
          onConfirm={onEditCell}
          sheetFields={sheetFields}
          editingEnabled={editedCell?.id === cell.id}
        />
      ) : (
        <CellLabelRenderer cell={cell} />
      )}
      {cell.columnIndex === rowSize && showConnectionHandles && (
        <>
          {sheet.edgeHandles
            ?.filter(
              (edgeHandle) =>
                edgeHandle.nodeType === "source" &&
                edgeHandle.rowIndex === cell.rowIndex
            )
            .map((edgeHandle, i) => (
              <Handle
                key={`edge-${edgeHandle.id}`}
                id={`edge-${edgeHandle.id}`}
                type="source"
                position={Position.Right}
                style={{
                  top: getNodeTopPos(
                    cell,
                    i,
                    edgeHandle.nodeType,
                    sheet.edgeHandles
                  ),
                  transform: "translateY(-50%)",
                  bottom: "auto",
                  background: "#4B91EC",
                  border: "1px solid white",
                }}
              />
            ))}
          {!resizeState.isActive && (
            <Handle
              type="source"
              position={Position.Right}
              id={`source-sheet-${cell.sheetId}-row-${cell.rowIndex}`}
              style={{
                visibility: connectionModeEnabled ? "visible" : "hidden",
              }}
              className="source-handle"
              isConnectable
            />
          )}
        </>
      )}
      {cell.columnIndex === 1 &&
        !isSheetSelected &&
        !isSheetsUIVisible &&
        showConnectionHandles && (
          <>
            <Handle
              type="target"
              position={Position.Left}
              id={`target-sheet-${cell.sheetId}-row-${cell.rowIndex}`}
              style={{
                visibility: connectionModeEnabled ? "visible" : "hidden",
              }}
              className="target-handle"
              isConnectable
              // onMouseOver={() => onCellHighlight(cell.id)}
              // onMouseOut={() => onCellHighlight(undefined)}
            />
            {sheet.edgeHandles
              ?.filter(
                (edgeHandle) =>
                  edgeHandle.nodeType === "target" &&
                  edgeHandle.rowIndex === cell.rowIndex
              )
              .map((edgeHandle, i) => (
                <Handle
                  key={`edge-${edgeHandle.id}`}
                  id={`edge-${edgeHandle.id}`}
                  type="target"
                  position={Position.Left}
                  style={{
                    top: getNodeTopPos(
                      cell,
                      i,
                      edgeHandle.nodeType,
                      sheet.edgeHandles
                    ),
                    transform: "translateY(-50%)",
                    bottom: "auto",
                    background: "none",
                    border: "none",
                  }}
                />
              ))}
          </>
        )}
      <CellContextMenu
        cell={cell}
        cellWithContextMenu={cellWithContextMenu}
        sheet={sheet}
        target="cell"
        showColumnContextMenu={showColumnContextMenu}
        showRowContextMenu={showRowContextMenu}
      />
    </div>
  )
}
export default CellRenderer
