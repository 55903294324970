import { supabase } from "../database/SupabaseConnector"
import { useEffect, useState } from "react"
import "./Onboarding.css"
import { useNavigate } from "react-router-dom"
import clsx from "clsx"

export const SignupPage = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [currentStep, setCurrentStep] = useState("BusinessInfo")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [validation, setValidation] = useState<Record<string, boolean>>()
  const [isNextStepButtonPressed, setNextStepButtonPressed] =
    useState<boolean>()
  const [isSignupButtonDisabled, setSignupButtonDisabled] = useState(false)
  const navigate = useNavigate()

  const handleSignUp = async () => {
    setSignupButtonDisabled(true)
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          customer_team: companyName,
        },
      },
    })
    setSignupButtonDisabled(false)
    if (!error) {
      navigate("/signup-success")
    } else {
      setError(error?.message || "")
    }
  }

  const handleNextStepButtonClick = () => {
    const validation = { companyName: !!companyName }
    setValidation(validation)
    setNextStepButtonPressed(true)

    if (validation.companyName) {
      setCurrentStep("UserInfo")
    }
  }

  useEffect(() => {
    setValidation({ companyName: !!companyName })
  }, [companyName])

  return (
    <div className="signup-page">
      {currentStep === "UserInfo" && (
        <>
          <div className="onboarding-label">Email</div>
          <input
            className="onboarding-input"
            onChange={({ target }) => {
              setEmail(target.value)
              setError("")
            }}
          />
          <div className="onboarding-label">Password</div>
          <input
            type="password"
            className="onboarding-input"
            onChange={({ target }) => {
              setPassword(target.value)
              setError("")
            }}
          />
          <div className="form-validation-error">{error}</div>
        </>
      )}
      {currentStep === "BusinessInfo" && (
        <>
          <div className="onboarding-label">First Name</div>
          <input
            className="onboarding-input"
            onChange={({ target }) => setFirstName(target.value)}
            value={firstName}
          />
          <div className="onboarding-label">Last Name</div>
          <input
            className="onboarding-input"
            onChange={({ target }) => setLastName(target.value)}
            value={lastName}
          />
          <div
            className={clsx("onboarding-label", {
              "invalid-onboarding-field":
                isNextStepButtonPressed && !validation?.companyName,
            })}
          >
            Copmany Name * (Required)
          </div>
          <input
            className="onboarding-input"
            onChange={({ target }) => setCompanyName(target.value)}
            value={companyName}
          />
        </>
      )}

      <div className="onboarding-nav-controls">
        {currentStep === "BusinessInfo" && (
          <button
            className="onboarding-nav-button"
            onClick={handleNextStepButtonClick}
          >
            Next
          </button>
        )}
        {currentStep === "UserInfo" && (
          <>
            <button
              disabled={isSignupButtonDisabled}
              className={clsx("onboarding-nav-button", {
                "onboarding-nav-button-disabled": isSignupButtonDisabled,
              })}
              onClick={handleSignUp}
            >
              Sign Up
            </button>
            <button
              className="onboarding-back-button"
              onClick={() => setCurrentStep("BusinessInfo")}
            >
              Back
            </button>
          </>
        )}
      </div>
    </div>
  )
}
