import { BaseEdge, ConnectionLineComponentProps, EdgeProps } from "reactflow"
import {
  getConditionToCellPath,
  getConnectToCellPath,
} from "../utils/rendering"
import { UISheetType } from "../types/UITypes"

export const EdgePreview = (edgeProps: ConnectionLineComponentProps) => {
  const { fromNode, fromX, fromY, toX, toY, fromPosition } = edgeProps
  let connection

  if (fromNode?.type === UISheetType.CONDITION) {
    // for flowchart condition element rowIndex 2 is reserved for the Yes choice on the right
    // and rowIndex 3 is reserved for the No choice on the left.
    const rowIndex = fromPosition === "right" ? 2 : 3

    connection = getConditionToCellPath(fromX, fromY, toX, toY, rowIndex)
  } else {
    connection = getConnectToCellPath(fromX, fromY, toX, toY)
  }

  const { edgePath } = connection

  return (
    <BaseEdge
      path={edgePath}
      style={{
        strokeWidth: 1.5,
        strokeOpacity: 0.8,
      }}
    />
  )
}
