import ReactModal from "react-modal"
import "./ModalDialog.css"
import { ReactComponent as CrossIcon } from "../assets/icons/cross-icon.svg"
import { supabase } from "../database/SupabaseConnector"
import { useState } from "react"

type UpgradeToProModalProps = ReactModal.Props & {
  customerTeam: string
}

export const UpgradeToProModal = (props: UpgradeToProModalProps) => {
  const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false)

  const handleUpgradeToProClick = async () => {
    try {
      setIsLoadingAIResponse(true)

      const { data: sessionData } = await supabase.auth.getSession()
      if (!sessionData.session) {
        console.log("Please log in to subscribe")
        setIsLoadingAIResponse(false)
        return
      }

      const { data, error } = await supabase.functions.invoke(
        "stripe_create_subscription",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionData.session.access_token}`,
          },
        }
      )
      setIsLoadingAIResponse(false)

      if (error) throw error

      // Handle case where user is already subscribed
      if (data.error === "already_subscribed") {
        console.log(data.message)
        return
      }

      if (!data.url) throw new Error("No checkout URL returned")

      // Redirect to Stripe Checkout
      window.location.href = data.url
    } catch (error) {
      console.log("Error:", error)
      console.log("Failed to start checkout process")
    }
  }

  return (
    <ReactModal
      {...props}
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: 10,
          zIndex: 10,
          borderRadius: 14,
          bottom: "auto",
          width: 400,
        },
        overlay: {
          zIndex: 10,
          background: "rgba(0,0,0,0.1)",
        },
      }}
    >
      {isLoadingAIResponse ? (
        <div className="loading-indicator">
          <span className="loading-indicator-span-1"></span>
          <span className="loading-indicator-span-2"></span>
        </div>
      ) : (
        <>
          <div className="modal-dialog-header">
            Manage your subscription
            <CrossIcon
              className="modal-dialog-close-icon"
              onClick={props.onRequestClose}
            />
          </div>
          <div
            className="modal-dialog-body"
            style={{ fontSize: 17, marginBottom: 10 }}
          >
            Your subscription has expired.
          </div>
          <div className="modal-dialog-footer" style={{ border: "none" }}>
            <button
              className="blue-button"
              onClick={handleUpgradeToProClick}
              style={{ marginTop: 5, marginRight: 10 }}
            >
              Upgrade To Pro
            </button>
          </div>
        </>
      )}
    </ReactModal>
  )
}
