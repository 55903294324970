import { createContext } from "react"
import {
  UISheet,
  UICell,
  EditorMode,
  SheetField,
  UIEdge,
  UIColumnLabel,
  PastedCellContent,
} from "./types/UITypes"
import { DatabaseCell, DatabaseLogic } from "./types/SupabaseTypesHelper"
import { Connection, EdgeProps, Node } from "reactflow"
import { RemovesCellsOptions } from "./utils/removeCells"
import { SheetValidationType } from "./utils/validation"

export type CellWithContextMenuInfoType = {
  cellId: string
  target: "rowIndex" | "cell"
}

type AppContextType = {
  selectedNodes: string[]
  selectedEdges: string[]
  setSelectedNodes: (value: string[]) => void
  updateCell: (
    value: string,
    cellId: string,
    sheetId: string
  ) => Promise<DatabaseCell | undefined>
  removeCells: (
    sheet: UISheet,
    cells: UICell[],
    options?: RemovesCellsOptions
  ) => Promise<void>
  removeEdge: (edgeProps: EdgeProps<UIEdge>) => Promise<void>
  updateSheet: (sheet: UISheet) => Promise<void>
  sheetFields: SheetField[]
  updateLogic: (logic: DatabaseLogic) => void
  connectionStart?: Pick<Connection, "source" | "sourceHandle">
  onConnect: (connection: Connection) => void
  onAddRow: (sheetId: string, rowIndex: number) => Promise<void>
  onRemoveRow: (sheetId: string, rowIndex: number) => Promise<void>
  onAddColumn: (sheetId: string, columnIndex: number) => Promise<void>
  onRemoveColumn: (sheetId: string, columnIndex: number) => Promise<void>
  onAddCells: (sheetId: string, cells: UICell[], nodes: Node[]) => Promise<void>
  onShrinkSheet: (
    sheetId: string,
    maxNumColumns: number | null,
    maxNumRows: number | null
  ) => Promise<void>
  onUpdateColumnLabel: (
    sheetId: string,
    columnIndex: number,
    text: string
  ) => Promise<void>
  onUpdateRowLabel: (
    sheetId: string,
    rowIndex: number,
    text: string
  ) => Promise<void>
  setIsEditingLabel: (value: boolean) => void
  isPendingApiResponse: boolean
  isSheetsUIVisible: boolean
  pendingSheetSelectionId: string | null
  setPendingSheetSelectionId: (id: string | null) => void
  setHighlightedPathDestCellId: (id?: string) => void
  onUpdateSheetName: (sheet: UISheet, value: string) => void
  onUpdateColumnLabelsLabel: (sheet: UISheet, value: string) => void
  onUpdateRowLabelsLabel: (sheet: UISheet, value: string) => void
  highlightedPathCellIds: string[]
  highlightedPathLogicIds: string[]
  sheetValidationErrors: SheetValidationType
  onPaste: (sheetId: string, pastedCells: Record<string, string>) => void
  onCellClick: (cell: UICell) => void
  selectedCell?: UICell
  editedCell?: UICell
  setEditedCell: (editedCell?: UICell) => void
  onOpenMatrixLabelEditor: (
    matrixId: string,
    labelType: string,
    labelText?: string
  ) => void
  cellWithContextMenu: CellWithContextMenuInfoType | undefined
  setCellWithContextMenu: (value: CellWithContextMenuInfoType) => void
  handleConditionResize: (value: UISheet) => void
}

export const AppContext = createContext<AppContextType>({
  selectedNodes: [],
  selectedEdges: [],
  setSelectedNodes: () => {},
  updateCell: () => new Promise(() => {}),
  removeCells: () => new Promise(() => {}),
  removeEdge: () => new Promise(() => {}),
  updateSheet: () => new Promise(() => {}),
  sheetFields: [],
  updateLogic: () => {},
  onConnect: () => {},
  onAddRow: () => new Promise(() => {}),
  onRemoveRow: () => new Promise(() => {}),
  onAddColumn: () => new Promise(() => {}),
  onRemoveColumn: () => new Promise(() => {}),
  onAddCells: () => new Promise(() => {}),
  onShrinkSheet: () => new Promise(() => {}),
  onUpdateColumnLabel: () => new Promise(() => {}),
  onUpdateRowLabel: () => new Promise(() => {}),
  setIsEditingLabel: () => {},
  isPendingApiResponse: false,
  isSheetsUIVisible: false,
  pendingSheetSelectionId: null,
  setPendingSheetSelectionId: () => {},
  setHighlightedPathDestCellId: () => {},
  onUpdateSheetName: () => {},
  onPaste: () => {},
  highlightedPathCellIds: [],
  highlightedPathLogicIds: [],
  sheetValidationErrors: {},
  onCellClick: () => {},
  setEditedCell: () => {},
  onUpdateColumnLabelsLabel: () => {},
  onUpdateRowLabelsLabel: () => {},
  onOpenMatrixLabelEditor: () => {},
  cellWithContextMenu: undefined,
  setCellWithContextMenu: () => {},
  handleConditionResize: () => {},
})
