import { Edge, Connection, Node } from "reactflow"
import { UISheet, UILogic, UIEdge, UISheetType } from "../types/UITypes"
import { createEdge } from "./canvasHelpers"
import { getAppearanceType } from "./common"
import { uuidv7 } from "uuidv7"

export const addCellToCellConnection = (
  nodes: Node<UISheet | UILogic>[],
  edges: Edge<UIEdge>[],
  connection: Connection,
  sourceSheetId: string,
  targetSheetId: string,
  sourceRowIndex: string,
  targetRowIndex: string
) => {
  const appearanceType = getAppearanceType(edges)
  const { source, target } = connection

  const sourceEntityType: UISheetType | undefined = nodes.find(
    ({ data }) => data.id === sourceSheetId
  )?.type as UISheetType

  const targetEntityType: UISheetType | undefined = nodes.find(
    ({ data }) => data.id === targetSheetId
  )?.type as UISheetType

  const sourceNodeUUID =
    sourceEntityType === UISheetType.CONDITION ? 1 : uuidv7()
  const targetNodeUUID = uuidv7()

  const newEdge = createEdge(
    source!,
    target!,
    sourceSheetId,
    targetSheetId,
    parseInt(sourceRowIndex),
    parseInt(targetRowIndex),
    null,
    null,
    `edge-source-sheet-${sourceSheetId}-row-${sourceRowIndex}-node-${sourceNodeUUID}`,
    `edge-target-sheet-${targetSheetId}-row-${targetRowIndex}-node-${targetNodeUUID}`,
    appearanceType,
    sourceEntityType,
    targetEntityType
  )

  const updatedEdges = [...edges, newEdge]

  const updatedNodes = nodes.map((node) =>
    node.id !== source && node.id !== target
      ? node
      : node.id === source
      ? {
          ...node,
          data: {
            ...node.data,
            edgeHandles: [
              ...(node.data.edgeHandles || []),
              {
                id: `source-sheet-${sourceSheetId}-row-${sourceRowIndex}-node-${sourceNodeUUID}`,
                rowIndex: parseInt(sourceRowIndex),
                nodeType: "source",
              },
            ],
          },
        }
      : {
          ...node,
          data: {
            ...node.data,
            edgeHandles: [
              ...(node.data.edgeHandles || []),
              {
                id: `target-sheet-${targetSheetId}-row-${targetRowIndex}-node-${targetNodeUUID}`,
                rowIndex: parseInt(targetRowIndex),
                nodeType: "target",
              },
            ],
          },
        }
  )

  return { updatedNodes, updatedEdges, newEdge }
}
