import {
  Handle,
  NodeResizeControl,
  Position,
  ResizeDragEvent,
  ResizeParams,
} from "reactflow"
import "./FlowChartChoiceBlock.css"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg"
import { ReactComponent as ResizeIcon } from "../../assets/icons/resize-handle.svg"
import { UICell, UISheet } from "../../types/UITypes"
import { useContext, useMemo, useState } from "react"
import { AppContext } from "../../AppContext"
import clsx from "clsx"
import { FormulaEditor } from "../FormulaEditor"

const HANDLE_SIZE = 5

type CustomNodeProps = { id: string; data: UISheet }

export const FlowChartChoiceBlock = ({ id, data }: CustomNodeProps) => {
  const { cells } = data

  const {
    selectedNodes,
    removeCells,
    updateCell,
    setEditedCell,
    editedCell,
    onCellClick,
    isSheetsUIVisible,
    handleConditionResize,
  } = useContext(AppContext)

  const isSelected = useMemo(() => selectedNodes.includes(id), [selectedNodes])

  const isConnectionModeEnabled = useMemo(
    () => !!selectedNodes.length,
    [selectedNodes]
  )

  const handleDeleteSheet = async () => {
    removeCells(data, cells, { removeAll: true })
  }

  const handleEditCell = async (
    formulaEditorInlineValue: string,
    editedCell: UICell
  ) => {
    const updatedCellName = formulaEditorInlineValue

    updateCell(updatedCellName, editedCell.id, id)

    setEditedCell(undefined)
  }

  const isUIVisible = isSheetsUIVisible || isSelected

  const [resizeState, setResizeState] = useState<any>({
    width: data.width,
    height: data.height,
  })

  const handleResize = (event: ResizeDragEvent, params: ResizeParams) => {
    const newWidth = Math.max(params.width, 200)
    const newHeight = Math.max(params.height, 100)

    setResizeState((resizeState: any) => ({
      ...resizeState,
      width: newWidth,
      height: newHeight,
    }))
  }

  const handleResizeEnd = (event: ResizeDragEvent, params: ResizeParams) => {
    handleResize(event, params)

    handleConditionResize({
      ...data,
      width: params.width,
      height: params.height,
    })
  }

  return (
    <div
      className={clsx("choice", {
        "selected-choice": isSelected,
      })}
      style={{ width: resizeState.width, height: resizeState.height }}
    >
      {isUIVisible && (
        <NodeResizeControl
          onResize={handleResize}
          onResizeEnd={handleResizeEnd}
          style={{
            background: "transparent",
            border: "none",
            transform: "translate(-11px, -11px)",
            zIndex: 100,
          }}
          minWidth={100}
          minHeight={50}
        >
          <ResizeIcon style={{ width: 21, height: 21 }} />
        </NodeResizeControl>
      )}

      <DeleteIcon
        style={{ width: 20, height: 20, zIndex: 10 }}
        className="delete-icon"
        onClick={handleDeleteSheet}
      />

      <Handle
        type="target"
        position={Position.Top}
        id={`target-sheet-${data.id}-row-1`}
        className={clsx("choice-source-handle-top", {
          "choice-source-handle-top-visible": isConnectionModeEnabled,
        })}
        isConnectable
      />

      <Handle
        id={`source-sheet-${data.id}-row-2`}
        type="source"
        position={Position.Right}
        className={clsx("choice-source-handle", {
          "choice-source-handle-visible": isConnectionModeEnabled,
        })}
      />
      <div
        className="flowchart-choice-label flowchart-choice-label-right"
        style={{ transform: "translateX(10px)" }}
      >
        Yes
      </div>
      <Handle
        id={`source-sheet-${data.id}-row-3`}
        type="source"
        position={Position.Left}
        className={clsx("choice-source-handle", "choice-source-handle-left", {
          "choice-source-handle-visible": isConnectionModeEnabled,
        })}
      />
      <div
        className="flowchart-choice-label flowchart-choice-label-left"
        style={{ transform: "translateX(10px)" }}
      >
        No
      </div>

      <Handle
        id={`edge-source-sheet-${data.id}-row-2-node-1`}
        type="source"
        position={Position.Right}
        style={{
          top: "50%",
          transform: "translate(-20px, -4px)",
          bottom: "auto",
          width: HANDLE_SIZE,
          height: HANDLE_SIZE,
          visibility: "hidden",
        }}
      />

      <Handle
        id={`edge-source-sheet-${data.id}-row-3-node-1`}
        type="source"
        position={Position.Left}
        style={{
          top: "50%",
          transform: "translate(20px, -4px)",
          bottom: "auto",
          width: HANDLE_SIZE,
          height: HANDLE_SIZE,
          visibility: "hidden",
        }}
      />

      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <path
          d="M0,50 50,0 100,50 50,98z"
          className="outer"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
      {cells[1] && (
        <div
          style={{
            transform: "translateY(30px)",
            width: "calc(100% - 20px)",
            color: "#808080",
            marginLeft: 10,
          }}
          onClick={() => onCellClick(cells[1])}
        >
          <FormulaEditor
            cell={cells[1]}
            onConfirm={handleEditCell}
            sheetFields={[]}
            editingEnabled={editedCell?.id === cells[1]?.id}
            textEditorStyles={{ textAlign: "center" }}
          />
        </div>
      )}
    </div>
  )
}
