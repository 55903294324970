import { Database } from "./SupabaseTypes"
import { UISheetType } from "./UITypes"

export type DatabaseCell = Database["public"]["Tables"]["cell"]["Row"]
export type DatabaseSheet = Database["public"]["Tables"]["sheet"]["Row"]
export type DatabaseEdge = Database["public"]["Tables"]["edge"]["Row"]
export type DatabaseLogic = Database["public"]["Tables"]["logic"]["Row"]
export type DatabaseCanvas = Database["public"]["Tables"]["canvas"]["Row"]
export type DatabaseColumnLabel =
  Database["public"]["Tables"]["column_label"]["Row"]
export type DatabaseRowLabel = Database["public"]["Tables"]["row_label"]["Row"]
export type DatabaseColumnLabelsLabel =
  Database["public"]["Tables"]["column_label_label"]["Row"]
export type DatabaseRowLabelsLabel =
  Database["public"]["Tables"]["row_label_label"]["Row"]
export type CustomerLLMUsage =
  Database["public"]["Tables"]["customer_rolling_month_llm_usage"]["Row"]
export type CustomerTeam = Database["public"]["Tables"]["customer_team"]["Row"]
export type CustomerSubscription =
  Database["public"]["Tables"]["customer_subscription"]["Row"]
export type SubscriptionTier =
  Database["public"]["Tables"]["subscription_tier"]["Row"]

export enum CellType {
  Number = 1,
  Text = 2,
  Date = 3,
}

export const CellTypeLabels: Record<string, string> = {
  [CellType.Number]: "Number",
  [CellType.Text]: "Text",
  [CellType.Date]: "Date",
}

export const DBEntityType = {
  [UISheetType.SHEET]: 1,
  [UISheetType.MATRIX]: 2,
  [UISheetType.CONDITION]: 3,
}

export const DBEntityTypeToUITypeMapping: Record<number, UISheetType> = {
  1: UISheetType.SHEET,
  2: UISheetType.MATRIX,
  3: UISheetType.CONDITION,
}
