import { BaseEdge, EdgeLabelRenderer, EdgeProps } from "reactflow"
import { UIEdge, UISheetType } from "../types/UITypes"
import {
  getCellToConditionPath,
  getColorFromAppearanceType,
  getConditionToCellPath,
} from "../utils/rendering"
import { getConnectToCellPath, getConnectToLogicPath } from "../utils/rendering"
import { useContext, useMemo } from "react"
import { AppContext } from "../AppContext"
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow-icon.svg"

export const EdgeRenderer = (edgeProps: EdgeProps<UIEdge>) => {
  const { sourceX, sourceY, targetX, targetY, targetHandleId, id, data } =
    edgeProps

  const targetNodeType = targetHandleId?.includes("sheet") ? "sheet" : "logic"
  const { selectedEdges, removeEdge } = useContext(AppContext)

  const isSelected = useMemo(() => selectedEdges.includes(id), [selectedEdges])

  const edgeColor = getColorFromAppearanceType(data?.appearanceType)

  let pathProps

  const isCellToConditionEdge = data?.targetEntityType === UISheetType.CONDITION

  const isConditionToCellEdge =
    data?.sourceEntityType === UISheetType.CONDITION &&
    data?.targetEntityType === UISheetType.SHEET

  if (isCellToConditionEdge) {
    pathProps = getCellToConditionPath(
      sourceX,
      sourceY,
      targetX,
      targetY,
      data.targetRowIndex!
    )
  } else if (isConditionToCellEdge) {
    pathProps = getConditionToCellPath(
      sourceX,
      sourceY,
      targetX,
      targetY,
      data.sourceRowIndex!
    )
  } else {
    pathProps =
      targetNodeType === "sheet"
        ? getConnectToCellPath(sourceX, sourceY, targetX, targetY)
        : getConnectToLogicPath(sourceX, sourceY, targetX, targetY)
  }

  const { edgePath, labelX, labelY } = pathProps

  const handleDelete = () => {
    removeEdge(edgeProps)
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={{
          strokeWidth: isSelected ? 2.5 : 1.5,
          stroke: edgeColor,
          strokeOpacity: 0.8,
        }}
      />

      {targetNodeType === "sheet" && (
        <EdgeLabelRenderer>
          <ArrowIcon
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(
                ${targetX - (isCellToConditionEdge ? 0 : 2)}px,
                ${targetY - (isCellToConditionEdge ? 4 : 0)}px
              )${isCellToConditionEdge ? " rotate(90deg)" : ""}`,
              zIndex: 12,
            }}
            color={edgeColor}
            className="arrow-icon"
          />
        </EdgeLabelRenderer>
      )}

      {isSelected && (
        <EdgeLabelRenderer>
          <div
            onClick={handleDelete}
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(
                ${labelX}px,
                ${labelY}px
              )`,
              zIndex: 12,
              pointerEvents: "all",
              cursor: "pointer",
              background: "#fff",
              width: 15,
              height: 15,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              border: `2.5px solid ${edgeColor}`,
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            x
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  )
}
