import { Edge } from "reactflow"
import { UISheetType } from "../types/UITypes"
import { uuidv7 } from "uuidv7"

export const createEdge = (
  sourceId: string,
  targetId: string,
  sourceSheetId: string | null,
  targetSheetId: string | null,
  sourceRowIndex: number | null,
  targetRowIndex: number | null,
  sourceLogicId: string | null,
  targetLogicId: string | null,
  sourceHandleId: string,
  targetHandleId: string,
  appearanceType: number,
  sourceEntityType?: UISheetType,
  targetEntityType?: UISheetType
) => {
  const newEdgeId = uuidv7()

  return {
    id: `edge-${newEdgeId}`,
    source: sourceId,
    target: targetId,
    sourceHandle: sourceHandleId,
    targetHandle: targetHandleId,
    type: "custom",
    data: {
      id: newEdgeId,
      isNew: true,
      appearanceType,
      sourceSheetId,
      targetSheetId,
      sourceLogicId,
      targetLogicId,
      sourceRowIndex,
      targetRowIndex,
      sourceEntityType,
      targetEntityType,
    },
  } as Edge
}
