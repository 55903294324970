import { Edge, Node } from "reactflow"
import {
  DatabaseCell,
  DatabaseColumnLabel,
  DatabaseColumnLabelsLabel,
  DatabaseEdge,
  DatabaseRowLabel,
  DatabaseRowLabelsLabel,
  DatabaseSheet,
  DBEntityType,
} from "../types/SupabaseTypesHelper"
import { UIEdge, UILogicType, UISheet, UISheetType } from "../types/UITypes"
import { getUIEdgeData } from "./SupabaseConnector"

export const createUISheetData = (
  sheetData: DatabaseSheet,
  cellsData: DatabaseCell[] | null,
  edgesData: DatabaseEdge[] | null,
  columnLabelsData: DatabaseColumnLabel[] | null,
  rowLabelsData: DatabaseRowLabel[] | null,
  columnLabelsLablelData: DatabaseColumnLabelsLabel[] | null,
  rowLabelsLablelData: DatabaseRowLabelsLabel[] | null
): Node<UISheet> => {
  let nodeType = sheetData.complex ? UISheetType.MATRIX : UISheetType.SHEET

  if (sheetData.entity_type === DBEntityType[UISheetType.CONDITION]) {
    nodeType = UISheetType.CONDITION
  }

  const result: Node<UISheet> = {
    id: `sheet-${sheetData.id}`,
    // @ts-ignore
    position: {
      x: sheetData.position_x || 0,
      y: sheetData.position_y || 0,
    },
    data: {
      id: sheetData.id,
      name: sheetData.name || "",
      x: sheetData.position_x || 0,
      y: sheetData.position_y || 0,
      isDataSheet: !!sheetData.is_data_sheet,
      complex: !!sheetData.complex,
      cells: (cellsData || [])
        .filter(({ sheet_id }) => sheet_id === sheetData.id)
        .map((cellData) => ({
          id: cellData.id,
          name: cellData.name || "",
          value: cellData.value || "",
          operator: cellData.operator || "",
          valueType: !!sheetData.is_data_sheet ? cellData.cell_type : undefined,
          rowIndex: cellData.row_index ?? 1,
          columnIndex: cellData.column_index || 1,
          sheetId: cellData.sheet_id!,
        }))
        .sort((a, b) => a.rowIndex - b.rowIndex),
      edgeHandles: [
        ...getEdgeNodes(sheetData.id, "sheet", "source", edgesData),
        ...getEdgeNodes(sheetData.id, "sheet", "target", edgesData),
      ],
      columnLabels: (columnLabelsData || [])
        .filter(({ sheet_id }) => sheet_id === sheetData.id)
        .map((cellData) => ({
          id: cellData.id,
          text: cellData.text || "",
          columnIndex: cellData.column_index || 1,
        }))
        .sort((a, b) => a.columnIndex - b.columnIndex),
      enityType: nodeType,
    },
    // Note: for some reason ReactFlow does not render sheets if there are no default size.
    width: sheetData.width ?? 250,
    height: sheetData.height ?? 100,
    type: nodeType,
  }

  if (nodeType === UISheetType.CONDITION) {
    result.data.width = sheetData.width
    result.data.height = sheetData.height
  }

  // @ts-ignore
  if (result.type === "matrix") {
    result.data.rowLabels = (rowLabelsData || [])
      .filter(({ sheet_id }) => sheet_id === sheetData.id)
      .map((labelData) => ({
        id: labelData.id,
        text: labelData.text || "",
        rowIndex: labelData.row_index || 1,
      }))
      .sort((a, b) => a.rowIndex - b.rowIndex)

    result.data.columnLabelsLabel =
      (columnLabelsLablelData || []).find(
        ({ sheet_id }) => sheet_id === sheetData.id
      )?.text || ""

    result.data.rowLabelsLabel =
      (rowLabelsLablelData || []).find(
        ({ sheet_id }) => sheet_id === sheetData.id
      )?.text || ""
  }

  // @ts-ignore
  return result
}

/**
 *
 * @param entityId - cell or logic id
 * @param entityType - either "cell" or "logic"
 * @param nodeType - either "source" or "target" node
 * @param edgesData - edges data
 * @returns UINodes array used by he
 */
export const getEdgeNodes = (
  entityId: string,
  entityType: "sheet" | "logic",
  nodeType: "source" | "target",
  edgesData?: DatabaseEdge[] | null
) => {
  const backendNodeType = nodeType === "source" ? "source" : "dest"
  const edgeConnectionIdKey = `${backendNodeType}_${entityType}_id`
  const rowIndexKey =
    entityType === "sheet" ? `${backendNodeType}_row_index` : ""

  const edgeNodeIndices: Record<string, number> = {
    source: 0,
    target: 0,
  }

  return (
    edgesData
      // @ts-ignore
      ?.filter((edge) => edge[edgeConnectionIdKey] === entityId)
      .map((edge, i) => {
        // @ts-ignore
        let rowIndexSubstr = ""
        let rowIndex: number | undefined

        if (rowIndexKey) {
          // TS does not seem to understand reflection. TODO: Figure out how to do it right.
          // @ts-ignore
          rowIndex = edge[rowIndexKey] as number
          rowIndexSubstr = rowIndexKey ? `-row-${rowIndex}` : ""
        }

        edgeNodeIndices[nodeType] += 1

        return {
          id: `${nodeType}-${entityType}-${
            // @ts-ignore
            edge[edgeConnectionIdKey]
          }${rowIndexSubstr}-node-${edgeNodeIndices[nodeType]}`,
          rowIndex,
          nodeType: nodeType,
        }
      }) || []
  )
}

export const createUIEdgesData = (
  edgesData: any,
  sheetsData: DatabaseSheet[],
  logicsData: any
) => {
  const edges: Edge<UIEdge>[] = []
  const outgoingEdgesCount: Record<string, number> = {}
  const incomingEdgesCount: Record<string, number> = {}
  const sheetTypesById: Record<string, number> = {}

  sheetsData?.forEach((sheet: any) => {
    sheetTypesById[sheet.id] = sheet.entity_type
    outgoingEdgesCount[`sheet-${sheet.id}`] = 1
    incomingEdgesCount[`sheet-${sheet.id}`] = 1
  })

  logicsData?.forEach((logic: any) => {
    outgoingEdgesCount[`logic-${logic.id}`] = 1
    incomingEdgesCount[`logic-${logic.id}`] = 1
  })

  edgesData?.forEach((edgeData: any) => {
    const {
      id,
      source_sheet_id,
      dest_sheet_id,
      source_row_index,
      dest_row_index,
      source_logic_id,
      dest_logic_id,
    } = edgeData

    let sourceId = ""
    let targetId = ""

    if (source_sheet_id) {
      sourceId = `sheet-${source_sheet_id}` || ""
    } else {
      sourceId = `logic-${source_logic_id}` || ""
    }

    if (dest_sheet_id) {
      targetId = `sheet-${dest_sheet_id}` || ""
    } else {
      targetId = `logic-${dest_logic_id}` || ""
    }

    const sourceNodeType = !!source_sheet_id ? "sheet" : "logic"
    const destNodeType = !!dest_sheet_id ? "sheet" : "logic"

    let sourceNodeId

    if (source_sheet_id) {
      sourceNodeId = `${sourceNodeType}-${source_sheet_id}-row-${source_row_index}`
    } else {
      sourceNodeId = `${sourceNodeType}-${source_logic_id}`
    }

    let targetNodeId

    if (dest_sheet_id) {
      targetNodeId = `${destNodeType}-${dest_sheet_id}-row-${dest_row_index}`
    } else {
      targetNodeId = `${destNodeType}-${dest_logic_id}`
    }

    const edgeSourceHandleIndex =
      sheetTypesById[source_sheet_id] === DBEntityType[UISheetType.CONDITION]
        ? 1
        : outgoingEdgesCount[sourceId]++

    const edgeTargetHandleIndex =
      sheetTypesById[dest_sheet_id] === DBEntityType[UISheetType.CONDITION]
        ? 1
        : incomingEdgesCount[targetId]++

    edges.push({
      id: `edge-${id}`,
      source: sourceId,
      target: targetId,
      sourceHandle: `edge-source-${sourceNodeId}-node-${edgeSourceHandleIndex}`,
      targetHandle: `edge-target-${targetNodeId}-node-${edgeTargetHandleIndex}`,
      type: "custom",
      data: getUIEdgeData(edgeData, sheetsData),
    })
  })

  return edges
}

export const createUILogics = (
  logicsData: any,
  logicTypesData: any,
  edgesData: any
) => {
  return (logicsData || []).map((logicData: any) => ({
    id: `logic-${logicData.id}`,
    position: { x: logicData.position_x || 0, y: logicData.position_y || 0 },
    data: {
      id: logicData.id,
      name:
        (logicTypesData || []).find(
          ({ id }: any) => id === logicData.logic_type
        )?.text || "",
      logicType: logicData.logic_type || UILogicType.AND,
      edgeHandles: [
        ...getEdgeNodes(logicData.id, "logic", "source", edgesData),
        ...getEdgeNodes(logicData.id, "logic", "target", edgesData),
      ],
    },
    width: 50,
    height: 20,
    type: "logic",
  }))
}
