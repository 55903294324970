import { uuidv7 } from "uuidv7"
import { createUISheetData } from "../database/converters"
import { UICell, UILogic, UILogicType, UISheet } from "../types/UITypes"
import { supabase } from "../database/SupabaseConnector"
import { Node, ReactFlowInstance } from "reactflow"
import {
  DatabaseCell,
  DatabaseColumnLabel,
  DatabaseColumnLabelsLabel,
  DatabaseRowLabel,
  DatabaseRowLabelsLabel,
  DatabaseSheet,
} from "../types/SupabaseTypesHelper"

export const parseAutogenMatrices = async (
  importedApiJSON: any,
  canvasId: string,
  reactFlow: ReactFlowInstance
) => {
  const { matrices } = importedApiJSON
  const matricesData: any = []

  let matrixIndex = 0

  const baseCoords = reactFlow.project({
    x: window.innerWidth * 0.2,
    y: window.innerHeight * 0.3,
  }) ?? { x: 0, y: 0 }

  // TODO: fix types
  const matricesDimensoions: any = {}
  const columnWidth = 225
  const cellsData: DatabaseCell[] = []
  const columnLabelsData: DatabaseColumnLabel[] = []
  const rowLabelsData: DatabaseRowLabel[] = []
  const rowLabelsLabelData: DatabaseRowLabelsLabel[] = []
  const columnLabelsLablelData: DatabaseColumnLabelsLabel[] = []
  const matrixCellsMap: any = {}
  const matrixRowLabelsMap: any = {}
  const matrixColumnLabelsMap: any = {}
  const matrixRowLabelLabelsMap: any = {}
  const matrixColumnLabelLablelsMap: any = {}

  for (const matrixData of matrices) {
    const matrixId = uuidv7()
    const matrixColumnLabelLablel = {
      id: uuidv7(),
      sheet_id: matrixId,
      text: matrixData.col_label_label,
    }
    matrixColumnLabelLablelsMap[matrixId] = matrixColumnLabelLablel
    // ignore missing params like created_at, user_id, that automatically created by the DB
    // @ts-ignore
    columnLabelsLablelData.push(matrixColumnLabelLablel)

    const matrixRowLabelLabel = {
      id: uuidv7(),
      sheet_id: matrixId,
      text: matrixData.row_label_label,
    }
    matrixRowLabelLabelsMap[matrixId] = matrixRowLabelLabel
    // ignore missing params like created_at, user_id, that automatically created by the DB
    // @ts-ignore
    rowLabelsLabelData.push(matrixRowLabelLabel)
    const matrixRowLabels: DatabaseRowLabel[] = []

    matrixData.row_labels?.forEach((text: string, rowIndex: number) => {
      // ignore missing params like created_at, user_id, that automatically created by the DB
      // @ts-ignore
      matrixRowLabels.push({
        id: uuidv7(),
        sheet_id: matrixId,
        row_index: rowIndex + 1,
        text,
        canvas_id: canvasId,
      })
    })

    rowLabelsData.push(...matrixRowLabels)
    matrixRowLabelsMap[matrixId] = matrixRowLabels

    const matrixColumnLabels: DatabaseColumnLabel[] = []
    matrixData.column_labels?.forEach((text: string, columnIndex: number) => {
      // ignore missing params like created_at, user_id, that automatically created by the DB
      // @ts-ignore
      matrixColumnLabels.push({
        id: uuidv7(),
        sheet_id: matrixId,
        column_index: columnIndex + 1,
        text,
        canvas_id: canvasId,
      })
    })

    columnLabelsData.push(...matrixColumnLabels)
    matrixColumnLabelsMap[matrixId] = matrixColumnLabels

    const matrixCellsData: DatabaseCell[] = []
    matrixData.cells?.forEach((cellsRow: string[], rowIndex: number) => {
      cellsRow.forEach((cellText, columnIndex) => {
        // ignore missing params like created_at, user_id, that automatically created by the DB
        // @ts-ignore
        matrixCellsData.push({
          id: uuidv7(),
          sheet_id: matrixId,
          name: cellText,
          column_index: columnIndex + 1,
          row_index: rowIndex + 1,
          canvas_id: canvasId,
        })
      })
    })

    cellsData.push(...matrixCellsData)
    matrixCellsMap[matrixId] = matrixCellsData

    // ignore missing params like created_at, user_id, that automatically created by the DB
    // @ts-ignore
    const matrix: DatabaseSheet = {
      id: matrixId,
      name: matrixData.name,
      complex: true,
    }

    matrixIndex++

    matricesData.push(matrix)
  }

  cellsData.forEach((cell: any) => {
    if (!matricesDimensoions[cell.sheet_id]) {
      matricesDimensoions[cell.sheet_id] = { numColumns: 0, numRows: 0 }
    }

    if (matricesDimensoions[cell.sheet_id].numColumns < cell.column_index) {
      matricesDimensoions[cell.sheet_id].numColumns = cell.column_index
    }

    if (matricesDimensoions[cell.sheet_id].numRows < cell.row_index) {
      matricesDimensoions[cell.sheet_id].numRows = cell.row_index
    }
  })

  await Promise.all(
    matricesData.map((matrix: any, matrixIndex: number) => {
      const matrixRowIndex = Math.floor(matrixIndex / 3)
      const matrixColumnIndex = matrixIndex % 3

      const currentX =
        baseCoords.x +
        (matrixColumnIndex
          ? matricesData
              .slice(matrixRowIndex * 3, matrixRowIndex * 3 + matrixColumnIndex)
              .reduce((acc: number, matrix: any) => {
                const sheetColumns = matricesDimensoions[matrix.id].numColumns

                return acc + sheetColumns * columnWidth + 350
              }, 0)
          : 0)

      let currentY = baseCoords.y

      if (matrixRowIndex > 0) {
        currentY =
          baseCoords.y +
          matricesData
            .filter((_: any, i: number) => i % 3 === matrixColumnIndex)
            .reduce((acc: number, matrix: any) => {
              const matrixRows = matricesDimensoions[matrix.id].numRows

              return acc + matrixRows * 30 + 50
            }, 0)
      }
      matrix.position_x = currentX
      matrix.position_y = currentY

      return supabase.rpc("handle_create_matrix", {
        matrix,
        cells: matrixCellsMap[matrix.id],
        row_labels: matrixRowLabelsMap[matrix.id],
        column_labels: matrixColumnLabelsMap[matrix.id],
        matrix_row_label_label: matrixRowLabelLabelsMap[matrix.id],
        matrix_column_label_label: matrixColumnLabelLablelsMap[matrix.id],
        canvas_id: canvasId,
      })
    })
  )

  const importedMatrices: Node<UISheet>[] = matricesData.map(
    (matrixData: any) =>
      createUISheetData(
        matrixData,
        cellsData,
        null,
        columnLabelsData,
        rowLabelsData,
        columnLabelsLablelData,
        rowLabelsLabelData
      )
  )

  return importedMatrices
}
