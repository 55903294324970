// @ts-ignore
import Mexp from "math-expression-evaluator"
import { ApiCell, ApiLogic, PublishedApi } from "../database/SupabaseConnector"
import { CellType } from "../types/SupabaseTypesHelper"
import { SheetField, UILogicType } from "../types/UITypes"

const getTypedValue = (
  passedValue: unknown,
  cellType: CellType,
  requestData: Record<string, unknown>
) => {
  switch (cellType) {
    case CellType.Number: {
      let numericValue = parseFloat(passedValue as string)

      // check if cellName is a reference to another field in the requestData
      if (isNaN(numericValue) && requestData[passedValue as string]) {
        numericValue = parseFloat(requestData[passedValue as string] as string)
      }

      return numericValue
    }

    case CellType.Text: {
      return passedValue
    }

    default: {
      return passedValue
    }
  }
}

const numericOperators = ["eq", "gt", "lt", "ge", "le"]

const getCellType = (cell: ApiCell, requestData: Record<string, unknown>) => {
  const { operator, value, name } = cell
  // cell value might be a reference to another cell / request parameter
  const referenceValue = (value && requestData[value]) || value
  const leftHandSideValue = (name && requestData[name]) || name

  let calculatedCellType = CellType.Text

  if (
    operator &&
    numericOperators.includes(operator) &&
    !isNaN(referenceValue as number) &&
    !isNaN(leftHandSideValue as number)
  ) {
    calculatedCellType = CellType.Number
  }

  if (!calculatedCellType && operator && operator === "eq") {
    calculatedCellType = CellType.Text
  }

  return calculatedCellType
}

const evaluateCell = (
  cell: ApiCell,
  requestData: Record<string, unknown>
): boolean => {
  const { name, value, operator, cellLogicType, conditions } = cell

  if (cellLogicType && conditions) {
    const evaluatedConditions = conditions.map((condition) =>
      evaluateCell(condition, requestData)
    )

    return cellLogicType.toLowerCase() === "and"
      ? !evaluatedConditions.some((value) => !value)
      : evaluatedConditions.some((value) => value)
  }

  // cell value might be a reference to another cell / request parameter
  const referenceValue = (value && requestData[value]) || value
  const leftHandSideValue = (name && requestData[name]) || name

  let calculatedCellType = CellType.Text

  if (
    operator &&
    numericOperators.includes(operator) &&
    !isNaN(referenceValue as number) &&
    !isNaN(leftHandSideValue as number)
  ) {
    calculatedCellType = CellType.Number
  }

  if (!calculatedCellType && operator && operator === "eq") {
    calculatedCellType = CellType.Text
  }

  if (!name || !referenceValue || !calculatedCellType || !operator) {
    return true
  }

  const cellValue = getTypedValue(
    referenceValue,
    calculatedCellType,
    requestData
  )

  const passedValue = getTypedValue(
    leftHandSideValue,
    calculatedCellType,
    requestData
  )

  let result = undefined

  if (
    operator === "eq" &&
    (calculatedCellType === CellType.Number ||
      calculatedCellType === CellType.Text)
  ) {
    result = cellValue === passedValue
  }

  if (operator === "gt" && calculatedCellType === CellType.Number) {
    result = (passedValue as number) > (cellValue as number)
  }

  if (operator === "lt" && calculatedCellType === CellType.Number) {
    result = (passedValue as number) < (cellValue as number)
  }

  if (operator === "ge" && calculatedCellType === CellType.Number) {
    result = (passedValue as number) >= (cellValue as number)
  }

  if (operator === "le" && calculatedCellType === CellType.Number) {
    result = (passedValue as number) <= (cellValue as number)
  }

  if (result === undefined) {
    return false
  }

  return cell.isReverseCondition ? !result : result
}

const canBeEvaluatedAsBoolean = (cell: ApiCell) => {
  return (
    !!(cell.name && cell.operator && cell.value) ||
    !!(cell.cellLogicType && cell.conditions)
  )
}

const hasInputData = (cell: ApiCell, inputData: Record<string, unknown>) => {
  const cellHasInputData =
    (inputData[cell.name] !== undefined &&
      inputData[cell.name] !== null &&
      inputData[cell.name] !== "") ||
    (cell.value !== undefined &&
      cell.value !== null &&
      cell.value !== "" &&
      inputData[cell.value] !== undefined &&
      inputData[cell.value] !== null &&
      inputData[cell.value] !== "")

  if (cellHasInputData) {
    return true
  }

  if (cell.cellLogicType && cell.conditions) {
    const someCellConditionHasInputData: boolean[] = cell.conditions.map(
      (apiCell) => hasInputData(apiCell, inputData)
    )

    return someCellConditionHasInputData.some((value) => value)
  }

  return false
}

export const getAnswer = (
  cellOrLogic: ApiCell | ApiLogic,
  apiSheet: PublishedApi,
  inputData: Record<string, unknown>,
  isAnswerCell?: boolean
): boolean => {
  const cellCanBeEvaluated = canBeEvaluatedAsBoolean(cellOrLogic as ApiCell)
  const cellHasInputData = hasInputData(cellOrLogic as ApiCell, inputData)
  const cell = cellOrLogic as ApiCell
  let cellValue
  const isLogic = (cellOrLogic as ApiLogic).logicType !== undefined

  if (!isAnswerCell && cellCanBeEvaluated && cellHasInputData) {
    cellValue = evaluateCell(cell, inputData)
  }

  // Return true if the cell is empty
  if (
    cell.name === "" &&
    cell.operator === "" &&
    cell.value === "" &&
    !isLogic
  ) {
    cellValue = true
  }

  if (isLogic) {
    const logic = cellOrLogic as ApiLogic
    const logicAnswers = (logic.prev as Array<ApiCell | ApiLogic>).map(
      (prevCell) => getAnswer(prevCell, apiSheet, inputData)
    )

    const prevAnswer =
      logic?.logicType === UILogicType.AND
        ? !logicAnswers.some((answer) => !answer)
        : logicAnswers.some((answer) => answer)

    return prevAnswer
  }

  if (!isLogic && cellOrLogic.prev) {
    const prevAnswer = getAnswer(
      cellOrLogic.prev as ApiCell | ApiLogic,
      apiSheet,
      inputData
    )

    if (cellValue !== undefined) {
      return prevAnswer && cellValue
    }

    return prevAnswer
  }

  return cellValue ?? false
}

export const executeApi = (
  inputData: any,
  apiSheetId: string,
  api: PublishedApi[]
) => {
  const apiSheet = api.find(({ sheetId }) => sheetId === apiSheetId)

  if (!apiSheet) {
    return null
  }

  const results: any = []

  apiSheet.cells
    .sort((a, b) => a.rowIndex - b.rowIndex)
    .forEach((cell) => {
      const answer = getAnswer(cell, apiSheet, inputData, true)

      results.push(answer)
    })

  const mappedResults = results.map((result: boolean, i: number) => {
    const resultsRow = apiSheet.apiRows[i].sort(
      (a, b) => a.columnIndex - b.columnIndex
    )

    if (!result) {
      return resultsRow.map((cell) => ({
        id: cell.id,
        name: cell.name,
        value: false,
        rowIndex: cell.rowIndex,
        columnIndex: cell.columnIndex,
      }))
    }

    return resultsRow.map((cell) => {
      const mexp = new Mexp()
      let mathExpression = cell.value?.replace("=", "") || ""
      const inputVarNames = Object.keys(inputData)

      let isMathExpression = false

      inputVarNames.forEach((inputVarName) => {
        if (mathExpression.includes(inputVarName)) {
          isMathExpression = true

          mathExpression = mathExpression.replaceAll(
            inputVarName,
            inputData[inputVarName]
          )
        }
      })

      if (isMathExpression) {
        try {
          const evaluationResult = mexp.eval(mathExpression, [], inputData)

          return {
            id: cell.id,
            name: cell.name,
            value: evaluationResult,
            rowIndex: cell.rowIndex,
            columnIndex: cell.columnIndex,
          }
        } catch (e) {
          return {
            id: cell.id,
            name: cell.name,
            value: getTypedValue(
              cell.value,
              getCellType(cell, inputData),
              inputData
            ),
            rowIndex: cell.rowIndex,
            columnIndex: cell.columnIndex,
          }
        }
      } else {
        const typedCellValue = getTypedValue(
          cell.value,
          getCellType(cell, inputData),
          inputData
        )

        inputData[cell.name] = typedCellValue

        return {
          id: cell.id,
          name: cell.name,
          value: typedCellValue,
          rowIndex: cell.rowIndex,
          columnIndex: cell.columnIndex,
        }
      }
    })
  })

  return {
    apiSheet: {
      id: apiSheet.sheetId,
      name: apiSheet.sheetName,
    },
    values: mappedResults,
  }
}

export const getApiParams = (
  api: PublishedApi,
  sheetFields: SheetField[]
): object => {
  let apiParams: Record<string, string> = {}

  const apiVariables = sheetFields.reduce((acc, curr) => {
    return curr.alias && curr.name ? { ...acc, [curr.alias]: curr.name } : acc
  }, {})

  api.cells.forEach((cell) => {
    const cellParams = getCellParams(cell, apiVariables, true)
    apiParams = { ...apiParams, ...cellParams }
  })

  api.apiRows.forEach((answerCellsRow) => {
    answerCellsRow.forEach((answerCell) => {
      sheetFields.forEach((sheetField) => {
        if (sheetField.name) {
          if (
            answerCell.name?.includes(sheetField.name) ||
            answerCell.value?.includes(sheetField.name)
          ) {
            apiParams[sheetField.name] = ""
          }
        }
      })
    })
  })

  return { ...apiParams }
}

export const getCellParams = (
  cell: ApiCell | ApiLogic,
  apiVariables: Record<string, string>,
  isAnswerCell?: boolean
): object => {
  let cellResult: Record<string, string> = {}
  const apiCell = cell as ApiCell

  if (
    apiCell.name !== undefined &&
    apiCell.name !== "" &&
    apiCell.operator !== undefined &&
    apiCell.operator !== "" &&
    apiCell.value !== undefined &&
    apiCell.value !== "" &&
    !isAnswerCell
  ) {
    const cellName = (cell as ApiCell).name
    const apiParamName = apiVariables[cellName] || cellName

    cellResult = { [apiParamName]: "" }
  }

  if ((cell as ApiLogic).logicType !== undefined) {
    const prevCells = (cell.prev as Array<ApiCell | ApiLogic>).map((prev) => {
      return getCellParams(prev, apiVariables)
    })

    const prevResult = prevCells.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )

    return { ...cellResult, ...prevResult }
  }

  if ((cell.prev as ApiLogic)?.logicType === undefined && cell.prev) {
    const prevResult = getCellParams(cell.prev as ApiCell, apiVariables)

    return { ...cellResult, ...prevResult }
  }

  if ((cell.prev as ApiLogic)?.logicType !== undefined && cell.prev) {
    const prevCells = (cell.prev as ApiLogic).prev.map((prev) => {
      return getCellParams(prev, apiVariables)
    })

    const prevResult = prevCells.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )

    return { ...cellResult, ...prevResult }
  }

  const conditions = (cell as ApiCell).conditions

  if (conditions) {
    const conditionCells = conditions.map((prev) => {
      return getCellParams(prev, apiVariables)
    })

    const conditionCellsParams = conditionCells.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )

    return { ...cellResult, ...conditionCellsParams }
  }

  return cellResult
}
