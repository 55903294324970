import ReactModal from "react-modal"
import "./ModalDialog.css"
import { ReactComponent as CrossIcon } from "../assets/icons/cross-icon.svg"
import { useMemo, useState } from "react"
import { SubscriptionTier } from "../types/SupabaseTypesHelper"
import { supabase } from "../database/SupabaseConnector"

type ManageSubscriptionModalProps = ReactModal.Props & {
  isSubscriptionActive?: boolean | null
  usage?: number
  customerTeam?: string
  subscriptionExpiryDate?: string | null
  subscriptionTier?: SubscriptionTier
}

export const ManageSubscriptionModal = (
  props: ManageSubscriptionModalProps
) => {
  const {
    isSubscriptionActive,
    usage,
    subscriptionExpiryDate,
    onRequestClose,
    subscriptionTier,
  } = props

  const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false)

  const expirationDate = useMemo(
    () =>
      subscriptionExpiryDate
        ? new Date(subscriptionExpiryDate).toLocaleDateString()
        : "-",
    [subscriptionExpiryDate]
  )

  const handleSyncUpSubscriptionClick = async () => {
    await supabase.rpc("sync_stripe_subscriptions_to_customer_subscription")
  }

  const handleUpgradeToProClick = async () => {
    try {
      setIsLoadingAIResponse(true)

      const { data: sessionData } = await supabase.auth.getSession()
      if (!sessionData.session) {
        console.log("Please log in to subscribe")
        setIsLoadingAIResponse(false)
        return
      }

      const { data, error } = await supabase.functions.invoke(
        "stripe_create_subscription",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionData.session.access_token}`,
          },
        }
      )
      setIsLoadingAIResponse(false)

      if (error) throw error

      // Handle case where user is already subscribed
      if (data.error === "already_subscribed") {
        console.log(data.message)
        return
      }

      if (!data.url) throw new Error("No checkout URL returned")

      // Redirect to Stripe Checkout
      window.location.href = data.url
    } catch (error) {
      console.log("Error:", error)
      console.log("Failed to start checkout process")
    }
  }

  const llmUsageValue = useMemo(() => {
    if (!subscriptionTier) {
      return "-"
    }

    return `${usage ?? "0"} / ${subscriptionTier.monthly_llm_limit}`
  }, [usage, subscriptionTier])

  const shouldShowUpgradeToProButton = useMemo(() => {
    const llmLimit = subscriptionTier?.monthly_llm_limit
    // if usage, llmLimit or isSubscriptionActive is unknown, we don't show the Upgrade To Pro button
    if (
      usage === undefined ||
      usage === null ||
      llmLimit === undefined ||
      llmLimit === null ||
      isSubscriptionActive === undefined ||
      isSubscriptionActive === null
    ) {
      return false
    }

    return !isSubscriptionActive || llmLimit - usage <= 0
  }, [])

  return (
    <ReactModal
      {...props}
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: 10,
          zIndex: 10,
          borderRadius: 14,
          bottom: "auto",
          width: 500,
        },
        overlay: {
          zIndex: 10,
          background: "rgba(0,0,0,0.1)",
        },
      }}
    >
      <div className="modal-dialog-header">
        Manage your subscription
        <CrossIcon
          className="modal-dialog-close-icon"
          onClick={onRequestClose}
        />
      </div>
      <div className="modal-dialog-body">
        {isLoadingAIResponse ? (
          <div className="loading-indicator">
            <span className="loading-indicator-span-1"></span>
            <span className="loading-indicator-span-2"></span>
          </div>
        ) : (
          <>
            <div className="modal-dialog-line">
              <span className="manage-subscription-dialog-label">
                Subscription type:{" "}
              </span>
              {subscriptionTier?.name}
            </div>
            <div className="modal-dialog-line">
              <span className="manage-subscription-dialog-label">
                LLM Usage:{" "}
              </span>
              {llmUsageValue}
            </div>
            <div className="modal-dialog-line">
              <span className="manage-subscription-dialog-label">
                Subscription status:{" "}
              </span>
              {isSubscriptionActive ? "active" : "expired"}
            </div>
            <div className="modal-dialog-line">
              <span className="manage-subscription-dialog-label">
                Subscription expiration date:{" "}
              </span>
              {expirationDate}
            </div>
          </>
        )}
      </div>
      <div className="modal-dialog-footer" style={{ border: "none" }}>
        {!shouldShowUpgradeToProButton ? (
          <button
            className="blue-button"
            onClick={handleSyncUpSubscriptionClick}
            style={{ marginTop: 5, marginRight: 10 }}
          >
            Sync Up Subscription
          </button>
        ) : null}
        {shouldShowUpgradeToProButton ? (
          <button
            className="blue-button"
            onClick={handleUpgradeToProClick}
            style={{ marginTop: 5, marginRight: 10 }}
          >
            Upgrade To Pro
          </button>
        ) : (
          <button
            className="blue-button"
            onClick={onRequestClose}
            style={{ marginTop: 5, marginRight: 10 }}
          >
            Close
          </button>
        )}
      </div>
    </ReactModal>
  )
}
